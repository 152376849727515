<template>
  <div class="data_container">
    <div class="crumbs data_item">
      <el-breadcrumb separator-icon="ArrowRight">
        <el-breadcrumb-item :to="{ path: '/workbench' }">
          <i class="iconfont icon-zhuye"></i
        ></el-breadcrumb-item>
        <el-breadcrumb-item>交易管理</el-breadcrumb-item>
        <el-breadcrumb-item>中长期模拟交易</el-breadcrumb-item>
        <el-breadcrumb-item v-if="dialogImport">新增</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="data_item flex_between" v-if="!dialogImport">
      <div class="flex_start operation">
        <div class="flex_start">
          <div class="search_item flex_start">
            <span>任务日期：</span>
            <el-date-picker
              v-model="dateTime"
              type="date"
              placeholder="请选择"
              @change="changeType"
            />
          </div>

          <div class="search_item flex_start">
            <span class="search_title">创建人：</span>
            <el-input v-model="creator" placeholder="请输入" />
          </div>

          <div class="search_item flex_start">
            <span class="search_title">测算类型：</span>
            <el-select
              v-model="calculationType"
              class="select_day"
              placeholder="请选择"
            >
              <el-option
                v-for="item in typeList.data"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>

          <div class="search_item flex_start">
            <el-button type="primary" class="search">查询</el-button>
            <el-button type="info" class="search">重置</el-button>
          </div>
        </div>
      </div>

      <div class="flex_start">
        <el-button class="data_btns flex_center" @click="importFn">
          <i class="icon iconfont icon-xinzeng"></i>
          <span>新增</span>
        </el-button>
      </div>
    </div>

    <template v-if="!dialogImport">
      <div class="data_box">
        <el-table
          :data="forecastList.data"
          :header-cell-style="{
            background: '#022C63',
            height: '60px',
            color: '#FFFFFF',
          }"
          :cell-style="{
            background: '#0B183C',
            padding: '15px 0',
            color: '#FFFFFF',
          }"
          class="forecast_table"
        >
          <el-table-column prop="buyer" label="买方企业" align="center" />
          <el-table-column prop="seller" label="卖方企业" align="center" />
          <el-table-column prop="begin" label="合约开始时间" align="center" />
          <el-table-column prop="end" label="合约结束时间" align="center" />
          <el-table-column prop="power" label="合约电量" align="center" />
          <el-table-column prop="price" label="合约电价" align="center" />
          <el-table-column prop="average" label="合约均价" align="center" />
          <el-table-column prop="type" label="价格类型" align="center" />
          <el-table-column prop="status" label="合约状态" align="center" />
          <el-table-column prop="detail" label="详情" align="center">
            <el-button type="primary" link>详情</el-button>
          </el-table-column>
          <el-table-column prop="log" label="日志" align="center" />
        </el-table>
<!-- 
        <div class="flex_end">
          <el-pagination
            :page-size="20"
            :pager-count="6"
            layout="prev, pager, next"
            :total="1000"
            class="pages"
          />
        </div> -->
      </div>
    </template>

    <template v-else>
      <div class="data_box">
        <div class="stock_item">
          <div class="add_container">
            <div class="add_title_container flex_between">
              <div class="add_title">新增合约</div>
            </div>
            <div class="contract">
              <div class="contract_item flex_end">
                <span class="contract_title">对方类型</span>
                <el-select
                  v-model="dayVal"
                  class="contract_content"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in forecastCheck.data"
                    :key="item.day"
                    :label="item.name"
                    :value="item.day"
                  />
                </el-select>
              </div>

              <div class="contract_item flex_end">
                <span class="contract_title">我方性质</span>
                <el-select
                  v-model="dayVal"
                  class="contract_content"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in forecastCheck.data"
                    :key="item.day"
                    :label="item.name"
                    :value="item.day"
                  />
                </el-select>
              </div>

              <div class="contract_item flex_end">
                <span class="contract_title">对方地区</span>
                <el-select
                  v-model="dayVal"
                  class="contract_content"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in forecastCheck.data"
                    :key="item.day"
                    :label="item.name"
                    :value="item.day"
                  />
                </el-select>
              </div>

              <div class="contract_item flex_end">
                <span class="contract_title">对方企业</span>
                <el-select
                  v-model="dayVal"
                  class="contract_content"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in forecastCheck.data"
                    :key="item.day"
                    :label="item.name"
                    :value="item.day"
                  />
                </el-select>
              </div>

              <div class="contract_item flex_end">
                <span class="contract_title">开始时间</span>
                <div class="contract_content">
                  <el-date-picker
                    v-model="operation"
                    type="date"
                    placeholder="请选择"
                    @change="changeType($event, 1)"
                    value-format="YYYY-MM-DD"
                    :clearable="false"
                  />
                </div>
              </div>

              <div class="contract_item flex_end">
                <span class="contract_title">结束时间</span>
                <div class="contract_content">
                  <el-date-picker
                    v-model="operation"
                    type="date"
                    placeholder="请选择"
                    @change="changeType($event, 1)"
                    value-format="YYYY-MM-DD"
                    :clearable="false"
                  />
                </div>
              </div>

              <div class="contract_item flex_end">
                <span class="contract_title">合约电量</span>
                <div class="contract_content">
                  <el-input v-model="input2" placeholder="请输入">
                    <template #append>
                      <el-button disabled>万/千瓦时</el-button>
                    </template>
                  </el-input>
                </div>
              </div>

              <div class="contract_item flex_end">
                <span class="contract_title">价格类型</span>
                <div class="contract_content">
                  <el-select
                    v-model="dayVal"
                    class="contract_content"
                    placeholder="Select"
                  >
                    <el-option
                      v-for="item in forecastCheck.data"
                      :key="item.day"
                      :label="item.name"
                      :value="item.day"
                    />
                  </el-select>
                </div>
              </div>

              <div class="contract_item flex_end">
                <span class="contract_title">合约电价</span>
                <div class="contract_content">
                  <el-input v-model="input2" placeholder="请输入">
                    <template #append>
                      <el-button disabled>厘/千瓦时</el-button>
                    </template>
                  </el-input>
                </div>
              </div>

              <div class="contract_item flex_end">
                <span class="contract_title">交割节点</span>
                <div class="contract_content">
                  <el-select
                    v-model="dayVal"
                    class="contract_content"
                    placeholder="Select"
                  >
                    <el-option
                      v-for="item in forecastCheck.data"
                      :key="item.day"
                      :label="item.name"
                      :value="item.day"
                    />
                  </el-select>
                </div>
              </div>

              <div class="contract_item flex_end">
                <span class="contract_title">合约附件</span>
                <div class="contract_content flex_between">
                  <el-input v-model="input2" placeholder="文件名字"> </el-input>
                  <div class="flex_start btns">
                    <el-button type="primary">上传</el-button>
                    <el-button type="primary">下载</el-button>
                  </div>
                </div>
              </div>

              <div class="contract_item flex_end">
                <span class="contract_title">是否价格联动</span>
                <div class="contract_content">
                  <el-select
                    v-model="dayVal"
                    class="contract_content"
                    placeholder="Select"
                  >
                    <el-option
                      v-for="item in forecastCheck.data"
                      :key="item.day"
                      :label="item.name"
                      :value="item.day"
                    />
                  </el-select>
                </div>
              </div>
            </div>

            <div class="add_title_container flex_between">
              <div class="add_title">电量曲线设置</div>
            </div>

            <el-table
              :header-cell-style="{
                background: '#022C63',
                height: '60px',
                color: '#FFFFFF',
              }"
              :cell-style="{
                background: '#0B183C',
                padding: '15px 0',
                color: '#FFFFFF',
              }"
              class="forecast_table_add"
            >
              <el-table-column prop="buyer" label="买方企业" align="center" />
              <el-table-column prop="seller" label="卖方企业" align="center" />
              <el-table-column
                prop="begin"
                label="合约开始时间"
                align="center"
              />
              <el-table-column prop="end" label="合约结束时间" align="center" />
              <el-table-column prop="power" label="合约电量" align="center" />
              <el-table-column prop="price" label="合约电价" align="center" />
              <el-table-column prop="average" label="合约均价" align="center" />
              <el-table-column prop="type" label="价格类型" align="center" />
              <el-table-column prop="status" label="合约状态" align="center" />
              <el-table-column prop="detail" label="详情" align="center">
                <el-button type="primary" link>详情</el-button>
              </el-table-column>
              <el-table-column prop="log" label="日志" align="center" />
            </el-table>

            <div class="add_title_container flex_between">
              <div class="add_title">市场双边协商预览</div>
            </div>

            <div class="date_range">
              <div class="flex_between">
                <div class="flex_start">
                  <span class="date_tit">范围：&nbsp; </span>
                  <span> &nbsp; 年</span> <span> &nbsp; 月</span
                  ><span> &nbsp; 日</span>
                </div>
                <div class="flex_start">
                  <div class="flex_start date_select">
                    <span>开始时间：</span>
                    <el-date-picker
                      v-model="operation"
                      type="date"
                      placeholder="请选择"
                      @change="changeType($event, 1)"
                      value-format="YYYY-MM-DD"
                      :clearable="false"
                    />
                  </div>

                  <div class="flex_start date_select">
                    <span>结束时间：</span>
                    <el-date-picker
                      v-model="operation"
                      type="date"
                      placeholder="请选择"
                      @change="changeType($event, 1)"
                      value-format="YYYY-MM-DD"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="add_btns flex_end">
              <el-button type="info" @click="dialogImport = false"
                >取消</el-button
              >

              <el-button type="primary" @click="dialogImport = false"
                >保存</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import dialog from "../../components/dialog/dialog.vue";
// import importTemplate from "../../components/importTemplate/importTemplate.vue";
import { reactive, ref, getCurrentInstance, nextTick, onMounted } from "vue";
export default {
  name: "simulation",

  setup() {
    const { proxy } = getCurrentInstance(); // proxy是组件实例化对象

    let dateTime = ref(new Date());
    let dialogImport = ref(false);

    let creator = ref("");
    let calculationType = ref("");
    let typeList = reactive({
      data: [
        { label: "类型1", value: 1 },
        { label: "类型2", value: 2 },
      ],
    });

    let itemItem = reactive(13);

    const importFn = () => {
      dialogImport.value = true;
      console.log(dialogImport.value);
    };

    const changeType = (val) => {};

    let forecastList = reactive({
      data: [
        {buyer:'惠州市启庆科技有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2021-08',end:'2028-12',power:'100',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        {buyer:'广东东方面粉有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-01',end:'2028-12',power:'150',price:'0.8',average:'1.2',type:'线上',status:'合同变更',log:'--'},
        {buyer:'珠海市和平物流综合市场有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2021-03',end:'2028-12',power:'80',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        {buyer:'惠州市鼎盛荣物业管理有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2023-02',end:'2028-12',power:'160',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        {buyer:'惠州旭泰工艺实业有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2023-04',end:'2028-12',power:'200',price:'0.8',average:'1.2',type:'线上',status:'合同变更',log:'--'},
        {buyer:'惠州市维斯曼酒店有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-04',end:'2028-12',power:'350',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        {buyer:'惠州市亿鑫酒店有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2021-09',end:'2028-12',power:'200',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        {buyer:'惠州市华晖酒店有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2021-11',end:'2028-12',power:'60',price:'0.8',average:'1.2',type:'线上',status:'合同变更',log:'--'},
        {buyer:'惠州市西顿光电有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-08',end:'2028-12',power:'80',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        {buyer:'惠州市科为联创科技有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2021-09',end:'2028-12',power:'100',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        // {buyer:'惠州市钰塑精密制造有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2023-04',end:'2028-12',power:'120',price:'0.8',average:'1.2',type:'线上',status:'合同变更',log:'--'},
        // {buyer:'惠州市康惠顺实业有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2021-05',end:'2028-12',power:'130',price:'0.8',average:'1.2',type:'线上',status:'合同变更',log:'--'},
        // {buyer:'惠州市勤兴藤器有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-03',end:'2028-12',power:'210',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        // {buyer:'惠州明德影像科技有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-04',end:'2028-12',power:'160',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        // {buyer:'惠州市永涛塑胶科技有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2023-06',end:'2028-12',power:'320',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        // {buyer:'猛发运动科技(惠州)有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2023-03',end:'2028-12',power:'150',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        // {buyer:'惠阳晶鹏实业有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2021-08',end:'2028-12',power:'120',price:'0.8',average:'1.2',type:'线上',status:'合同变更',log:'--'},
        // {buyer:'佛山德联汽车用品有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-07',end:'2028-12',power:'130',price:'0.8',average:'1.2',type:'线上',status:'合同变更',log:'--'},
        // {buyer:'惠阳晶鹏实业有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-07',end:'2028-12',power:'120',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
        // {buyer:'惠州宏利五金塑胶制品厂有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2023-04',end:'2028-12',power:'150',price:'0.8',average:'1.2',type:'线上',status:'合同变更',log:'--'},
        // {buyer:'惠州宏利五金塑胶制品厂有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-10',end:'2028-12',power:'80',price:'0.8',average:'1.2',type:'线上',status:'新建合同',log:'--'},
      ],
    });

    

    // for (let i = 0; i < 13; i++) {
    //   forecastList.data.push({
    //     id: i + 1,
    //     buyer: "企业名字",
    //     seller: "企业名字",
    //     begin: "2022",
    //     end: "2022",
    //     power: "月度分解曲线",
    //     price: "蚂蚁管理员",
    //     average: "2022.08.09   11:00PM",
    //     type: "市场价",
    //     status: "状态",
    //     log: "蚂蚁管理员",
    //   });
    // }

    let task = ref(false);

    let forecastCheck = reactive({
      data: [
        { flag: false, name: "负荷预测信息", day: 0 },
        { flag: false, name: "备用预测信息", day: 0 },
        { flag: false, name: "必开必停组预测信息", day: 0 },
        { flag: false, name: "机组检修预测信息", day: 0 },
        { flag: false, name: "阻塞预测信息", day: 0 },
      ],
    });

    let actual = reactive({
      data: [
        { flag: false, name: "负荷预测信息", day: 0 },
        { flag: false, name: "备用预测信息", day: 0 },
        { flag: false, name: "必开必停组预测信息", day: 0 },
        { flag: false, name: "机组检修预测信息", day: 0 },
        { flag: false, name: "阻塞预测信息", day: 0 },
      ],
    });

    let transaction = reactive({
      data: [
        { flag: false, name: "日前电量", day: 0 },
        { flag: false, name: "实时电量", day: 0 },
      ],
    });

    let stockList = reactive({
      data: [
        { flag: false, name: "日前价格统一结算点", day: 0 },
        { flag: false, name: "实时价格统一结算点", day: 0 },
        { flag: false, name: "日前节点电价", day: 0 },
        { flag: false, name: "实时节点电价", day: 0 },
        { flag: false, name: "日前全省节点平均电价", day: 0 },
        { flag: false, name: "实时全省节点平均电价", day: 0 },
      ],
    });

    let weatherList = reactive({
      data: [
        { flag: false, name: "全省平均气温", day: 0 },
        { flag: false, name: "西南降水", day: 0 },
        { flag: false, name: "各地市温度", day: 0 },
      ],
    });

    let timeList = reactive({
      data: [
        { flag: false, name: "日出清电量", day: 0 },
        { flag: false, name: "历史电量", day: 0 },
        { flag: false, name: "粤易电", day: 0 },
      ],
    });

    let monthly = reactive({
      data: [
        { flag: false, name: "月度申报电量", dayStart: 0, dayEnd: 0, month: 0 },
        { flag: false, name: "月度实际电量", dayStart: 0, dayEnd: 0, month: 0 },
      ],
    });
    let wholesale = reactive({
      data: [
        { flag: false, name: "日清算结果", dayStart: 0, dayEnd: 0, month: 0 },
        { flag: false, name: "日账单", dayStart: 0, dayEnd: 0, month: 0 },
        { flag: false, name: "月账单", dayStart: 0, dayEnd: 0, month: 0 },
      ],
    });

    let retail = reactive({
      data: [
        {
          flag: false,
          name: "交易中心零售账单",
          dayStart: 0,
          dayEnd: 0,
          month: 0,
        },
      ],
    });

    const setting = () => {
      task.value = true;
    };

    onMounted(() => {
      nextTick(() => {});
    });
    return {
      dateTime,
      changeType,
      forecastList,
      task,
      setting,
      forecastCheck,
      actual,
      transaction,
      stockList,
      weatherList,
      timeList,
      monthly,
      wholesale,
      retail,
      dialogImport,
      importFn,

      creator,
      calculationType,
      typeList,
      itemItem,
    };
  },
};
</script>

<style lang="scss" scoped>
.data_box {
  // display: flex;
  // justify-content: space-between;
  margin-bottom: 15px;
}

.data_title {
  margin: 16px 0;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-indent: 1em;

  &::before {
    display: block;
    content: "";
    width: 6px;
    height: 20px;
    background: linear-gradient(135deg, #0081ff 0%, #22cce2 100%);
    border-radius: 3px 3px 3px 3px;
  }
}

.data_container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  .data_item {
    width: 100%;
    margin-bottom: 24px;

    .operation {
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;

      &:deep(.el-input__wrapper) {
        background: #1f2935;
        box-shadow: none;
      }
      &:deep(.el-input__inner) {
        color: #ffffff;
      }
    }
  }
  .crumbs {
    font-size: 14px;
    &:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner) {
      color: #ffffff;
    }
  }
}

.forecast_table,
.forecast_table_add {
  width: 100%;
  background: #0b183c;

  border-radius: 8px;
  --el-table-border: 2px solid #090a1f;
  --el-table-tr-bg-color: transparent;

  &:deep(.el-scrollbar) {
    padding: 0 10px 15px;
    box-shadow: inset 0px 0px 10px 0px #3767ad;
  }
  &:deep(.el-table__inner-wrapper::before) {
    background: #090a1f;
  }
}
.forecast_table_add {
  margin-bottom: 34px;
  margin-top: 26px;
}
.tag_box {
  min-width: 70px;
}
.tag {
  width: 10px;
  height: 10px;
  background: #ff8a48;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
}
.tag_green {
  width: 10px;
  height: 10px;
  background: #09b66d;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
}
.tag_blue {
  width: 10px;
  height: 10px;
  background: #22cce2;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
}
.data_btns {
  background: linear-gradient(180deg, #3090e4 0%, #09d3d5 100%);
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-right: 15px;
  border: none;
  &:deep(.icon) {
    padding-right: 10px;
  }
}

.value_point {
  width: 33px;
}

.selection_btns {
  // width: 100%;
  padding: 32px;
  background: #0b183c;
}
.column_title_top {
  border-radius: 8px 8px 0 0 !important;
}
.column_title_bottom {
  border-radius: 0 0 8px 8px !important;
}
.search_item {
  margin-right: 30px;

  .search_title {
    //  width: 60px;
    white-space: nowrap;
  }
}

.pages {
  margin-top: 25px;
  padding: 4px 0;
  background-color: #1f2935;
  --el-pagination-bg-color: #1f2935;
  border-radius: 6px;
  color: #ffffff;
  &:deep(button:disabled) {
    background-color: #1f2935;
  }
  &:deep(.el-pager li.is-active) {
    color: #ffffff;
    background: #0081ff;
    border-radius: 8px;
    margin: 0;
  }
  &:deep(.btn-prev) {
    color: #ffffff;
  }
  &:deep(.btn-next) {
    color: #ffffff;
  }
  &:deep(.btn-quickprev) {
    color: #ffffff;
  }
  &:deep(.btn-quicknext) {
    color: #ffffff;
  }
}

.stock_item {
  width: 100%;
  margin-bottom: 24px;

  .operation {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;

    &:deep(.el-date-editor.el-input__wrapper) {
      background: #1f2935;
      box-shadow: none;
    }

    &:deep(.select_day) {
      width: 120px;
      margin: 0 32px;
    }
    &:deep(.el-select .el-input__wrapper) {
      background: #1f2935;
      box-shadow: none;
    }
    &:deep(.day_type .el-select__tags .el-tag--info) {
      background: #e0eaff;
    }
  }
}
.add_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  box-shadow: inset 0px 0px 10px 0px #3767ad;
  background: #0b183c;
  margin-bottom: 30px;
  border-radius: 8px;
  padding: 20px 0;

  .contract {
    width: 100%;
    padding: 24px;
    padding-bottom: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .contract_item {
      width: 30%;
      margin-bottom: 24px;
      :deep(.el-input__wrapper) {
        background: #0a1f50;
        box-shadow: none;
      }
      :deep(.el-date-editor.el-input) {
        --el-date-editor-width: 100%;
      }
      :deep(.el-input .el-input-group__append) {
        background: #0a1f50;
        box-shadow: none;
      }

      :deep(.el-input-group__append button.el-button) {
        background: #0256ff48;
        border-radius: 5px;
      }
      .contract_title {
        font-size: 14px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        white-space: nowrap;
        margin-right: 10px;
      }
      .contract_content {
        width: 410px;
        .btns {
          margin-left: 22px;
        }
      }
    }
  }
  #average {
    width: 100%;
    height: 230px;
  }
  #stock {
    width: 100%;
    height: 230px;
  }
  .add_title_container {
    width: 100%;
    &:deep(.el-select .el-input__wrapper) {
      width: 150px;
      background: transparent;
      // box-shadow:none;
      margin-right: 30px;
    }
    .add_title {
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-indent: 2em;

      &::before {
        display: block;

        content: "";
        width: 4px;
        height: 25px;
        background: linear-gradient(135deg, #0081ff 0%, #22cce2 100%);
        border-radius: 0px 2px 2px 0px;
      }
    }
  }
}
.date_range {
  margin: 26px 23px;

  padding: 30px 16px;
  width: 100%;
  border: 1px solid #293657;
  background: #0f1b3c;
  .date_tit {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #8291a9;
    white-space: nowrap;
  }
  .date_select {
    &:deep(.el-input__wrapper) {
      box-shadow: none;
      background: #0a1f50;
    }
  }
  .date_select:nth-of-type(1) {
    margin-right: 24px;
  }
}
.add_btns {
  width: 100%;
  margin: 60px 0;
  margin-top: 40px;
  padding: 0 23px;
}
</style>
